<template>
    <div class="d-flex flex-column gap-3">
        <div class="bg-white rounded p-3" v-if="channelConfig">
            <span class="vstack gap-2">
                <div class="vstack gap-2" v-if="channelConfig.modules.crm">
                    <div>
                        CRM
                    </div>
                    <div class="d-flex flex-column gap-2 px-4">
                        <div>
                            <b-form-checkbox class="d-flex gap-2" v-model="channelConfig.modules.rdStation">
                                RD Station
                            </b-form-checkbox>
                            <div class="d-flex flex-column gap-1 px-4" v-if="channelConfig.modules.rdStation">
                                <b-form-group label="Token">
                                    <b-form-input class="w-100" v-model="channelConfig.rdInstanceToken"/>
                                </b-form-group>
                                <b-form-group label="Departamentos">
                                    <div class="position-relative">
                                        <b-collapse id="collapseDepSelect" ref="collapseDepSelect" class="mt-2 position-absolute bg-white border w-100 rounded overflow-y-auto" style="z-index: 1; bottom: 2.5em; max-height: 70vh">
                                            <b-form-checkbox-group
                                                v-model="channelConfig.rdDepartments"
                                                @change="verifyDepartments"
                                                style="z-index: 1"
                                            >
                                                <b-form-checkbox class="text-secondary small-text d-flex align-items-center border p-3 w-100" role="button" value="all" @change="verifyAllChecked">
                                                    <div class="ms-1" role="button">Todos</div>
                                                </b-form-checkbox>
                                                <div v-for="item in departments" :key="item._id">
                                                    <b-form-checkbox class="text-secondary small-text d-flex align-items-center border p-3 w-100" role="button" :value="item._id">
                                                        <div class="ms-1" role="button">{{ item.name }}</div>
                                                    </b-form-checkbox>
                                                </div>
                                            </b-form-checkbox-group>
                                            <div class="w-100 h-100 position-fixed top-0 end-0 start-0 bottom-0" role="window" style="z-index: -1" v-b-toggle.collapseDepSelect>
                                            </div>
                                        </b-collapse>
                                        <div class="border border-grey rounded w-100 py-2 px-3 d-flex justify-content-between" role="button" v-b-toggle.collapseDepSelect>
                                            <span v-if="channelConfig.rdDepartments?.length">
                                                <span v-if="channelConfig.rdDepartments.at(0) == 'all'">
                                                    Todos
                                                </span>
                                                <span v-else>
                                                    <span v-for="(dep,i) in channelConfig.rdDepartments" :key="dep._id">
                                                        <span>
                                                            {{ depName(dep) }}<span v-if="i+1 < channelConfig.rdDepartments.length">,</span> 
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                            <span v-else>
                                                &nbsp;
                                            </span>
                                            <span>
                                                <b-icon class="text-dark" icon="chevron-down" style="margin-right: -0.9em" font-scale="0.75"></b-icon>
                                            </span>
                                        </div>
                                    </div>
                                </b-form-group>
                                <b-form-group label="Plano">
                                    <b-form-select class="w-100 px-3 py-2 rounded" style="border-color: #e5e5e5;" v-model="channelConfig.rdPlan">
                                        <b-form-select-option value="free">Free</b-form-select-option>
                                        <b-form-select-option value="basic">Basic</b-form-select-option>
                                        <b-form-select-option value="pro">Pro</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </div>
                        </div>
                        <div>
                            <b-form-checkbox class="d-flex gap-2" v-model="channelConfig.modules.futureConnect">
                                CRM Gotalk
                            </b-form-checkbox>
                            <div class="d-flex flex-column gap-1 px-4" v-if="channelConfig.modules.futureConnect">
                                <b-form-group label="Token">
                                    <b-form-input class="w-100" v-model="channelConfig.futureConnectToken"/>
                                </b-form-group>
                                <b-form-group label="Login">
                                    <b-form-input class="w-100" v-model="channelConfig.futureConnectLogin"/>
                                </b-form-group>
                                <b-form-group label="Senha">
                                    <b-form-input type="password" class="w-100" v-model="channelConfig.futureConnectPassword"/>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="hstack gap-1">
                    <b-form-checkbox class="hstack gap-2" v-model="channelConfig.modules.backOffice">
                        BackOffice
                    </b-form-checkbox>
                    <div class="text-secondary">
                        - Módulo que dá a possibilidade de contatos que não estejam vinculados serem direcionados a um departamento ou operador específico
                    </div>
                </div>
                <div class="hstack gap-1">
                    <b-form-checkbox class="hstack gap-2" v-model="channelConfig.modules.chatbotForms">
                        Formulário
                    </b-form-checkbox>
                    <div class="text-secondary">
                        - Módulo que dá a possibilidade de solicitar informações antes de o contato ser direcionado a um departamento ou operador
                    </div>
                </div>
                <div class="hstack gap-1">
                    <b-form-checkbox class="hstack gap-2" v-model="channelConfig.modules.kanban" @change="verifyKanban">
                        Kanban
                    </b-form-checkbox>
                    <div class="text-secondary">
                        - Módulo que dá a possibilidade de gerenciar os estágios de venda de um cliente
                    </div>
                </div>
                <div class="vstack gap-2" v-if="!channelConfig.enableOperationalChannel">
                    <div class="hstack gap-1">
                        <b-form-checkbox class="hstack gap-2" v-model="channelConfig.modules.campaigns">
                            Campanhas Facebook/Instagram
                        </b-form-checkbox>
                        <div class="text-secondary">
                            - Módulo que dá a possibilidade de levar um atendimento originado de um anúncio para uma ação de menu
                        </div>
                    </div>
                    <div class="ps-4" v-if="channelConfig.modules.campaigns">
                        <b-form-group label-class="fw-bold" label="Selecionar Menu:">
                            <div class="position-relative">
                                <div class="border rounded w-100 text-grey py-1 px-3 d-flex justify-content-between" style="border-color: #eee !important" role="button" v-b-toggle.collapseOptionSelect>
                                    <span v-if="selectedMenu">
                                        {{ selectedMenu.option ||  selectedMenu.preTitle }} - {{ selectedMenu.textOption || selectedMenu.title }}
                                    </span>
                                    <span v-else>
                                        Selecione um menu
                                    </span>
                                    <span>
                                        <b-icon class="text-dark" icon="chevron-down" style="margin-right: -0.9em" font-scale="0.75"></b-icon>
                                    </span>
                                </div>
                                <b-collapse id="collapseOptionSelect" ref="collapseOptionSelect" class="position-absolute bg-white border w-100 rounded" style="z-index: 1" v-model="collapseOptionSelectVisible" v-if="selectMenuOptions">
                                    <div v-for="item in selectMenuOptions" :key="item.id">
                                        <tree-select-options 
                                            :submenus="item.submenus"
                                            :text="item.text"
                                            :value="item.value"
                                            :depth="0"
                                        /> 
                                    </div>
                                    <div class="w-100 h-100 position-fixed top-0 end-0 start-0 bottom-0" role="window" style="z-index: -1" v-b-toggle.collapseOptionSelect></div>
                                </b-collapse>
                            </div>
                        </b-form-group>
                    </div>
                </div>
            </span>
        </div>
        <div class="bg-white rounded p-3">
            <b-button class="me-2 text-white" variant="green" @click="saveConfig">Salvar</b-button>
            <!-- <b-button class="border" variant="secondary-outline">Cancelar</b-button> -->
        </div>
    </div>
</template>

<script>
import api from '../services/apiService.js'
import TreeSelectOptions from './treeSelectOptions.vue'

export default {
    components: {
        TreeSelectOptions
    },
    props: [
        'user',
        'socket',
    ],
    mounted() {
        this.$nextTick(function() {
            this.getChannelConfig()
            this.getDepartments()
            this.getMenus()
            this.$root.$on('selectMenu', value => {
                this.selectMenu(value)
                this.collapseOptionSelectVisible = false
            })
        })
    },
    data() {
        return {
            channelConfig: null,
            departments: [],
            selectMenuOptions: [],
            menuId: null,
            collapseOptionSelectVisible: false,
            selectedMenu: null,
        }
    },
    methods: {
        async getChannelConfig() {
            const resp = await api.getChannelConfig(this.user.channelId || this.user.roleId)

            if(resp?.statusCode == 200) {
                this.channelConfig = resp.channelConfig
                this.selectedMenu = this.channelConfig.campaignMenu
            }
        },
        async getDepartments(){
            const resp = await api.getDepartments(this.user.channelId || this.user.roleId)
            if(resp.statusCode != 200){
                this.departments = []
                this.departments['total'] = 0
                return
            }
            this.departments = resp.departments
            // console.log('departments',this.departments)
        },
        async saveConfig() {
            this.channelConfig.campaignMenu = {
                id: this.selectedMenu._id,
                topMenuId: this.selectedMenu.topMenuId,
                option: this.selectedMenu.option,
                textOption: this.selectedMenu.textOption,
                title: this.selectedMenu.title,
                preTitle: this.selectedMenu.preTitle,
            }

            if(this.channelConfig.rdDepartments?.includes('all') && this.channelConfig.rdDepartments.length > 1) {
                this.channelConfig.rdDepartments = ['all' ]
            }

            const saveConfig = await api.updateChannelConfig(this.channelConfig)
            if(saveConfig.statusCode === 200) {
                this.$emit('channelConfig',this.channelConfig)
                this.socket.emit("update_channel_config",this.channelConfig)
                this.$emit('msg', {
                    text:'Módulos alterados com sucesso!',
                    success: true,
                })
                return
            }

            this.$emit('msg', {
                text:'Ocorreu um erro, tente novamente mais tarde!',
                success: false,
            })
        },
        depName(_id) { // returns the name of the given _id department
            if(this.departments.length){
                const dep = this.departments.find(el=>el._id == _id)
                if(dep)
                    return dep.name
            }
        },
        verifyDepartments(e) {
            if(e.includes("all")) {
                if(e.at(-1) != "all") {
                    this.channelConfig.rdDepartments = this.channelConfig.rdDepartments.filter(e => e != "all")
                } else {
                    this.channelConfig.rdDepartments = ["all"]
                    this.departments.forEach(element => {
                        this.channelConfig.rdDepartments.push(element._id)
                    })
                }
            }
        },
        verifyAllChecked(e) {
            if(!e.includes("all")) {
                this.channelConfig.rdDepartments = []
            }
        },
        async getMenus() {
            const resp = await api.getMenus(this.user.channelId || this.user.roleId)
            console.log('resp',resp)
            if(resp.statusCode == 200) {
                if(resp.menus.length) {
                    const promise = resp.menus.map(async menu => {
                        if(menu.menus.length) {
                            const p = menu.menus.map(async el => {
                                if(typeof el == 'object') {
                                    if(el.action == 'submenu') {
                                        const menuRedirect = resp.menus.find(menu => menu._id == el.redirectId)
                                        if(menuRedirect)
                                            if(menuRedirect?.interactive?.type) 
                                                el.actionType = menuRedirect.interactive.type
                                            else
                                                el.actionType = el.action
                                    } else
                                        el.actionType = el.action
                                    el.topMenuId = menu._id || undefined
                                    return el
                                }
                            })
                            await Promise.all(p)
                        }
                        if(menu.interactive?.action?.sections?.length) {
                            let p = menu.interactive.action.sections.map(async el => {
                                for(let row of el.rows) {
                                    if(row.action == 'submenu') {
                                        let menuRedirect = resp.menus.find(menu => menu._id == row.redirectId)
                                        if(menuRedirect)
                                            if(menuRedirect?.interactive?.type) 
                                                row.actionType = menuRedirect.interactive.type
                                            else
                                                row.actionType = el.action
                                    } else
                                        row.actionType = row.action
                                    row.topMenuId = menu._id || undefined
                                }
                                return el
                            })
                            await Promise.all(p)
                        }
                        if(menu.interactive?.action?.buttons?.length) {
                            const p = menu.interactive.action.buttons.map(async el => {
                                if(el.reply.action == 'submenu') {
                                    const menuRedirect = resp.menus.find(menu => menu._id == el.reply.redirectId)
                                    if(menuRedirect)
                                        if(menuRedirect.interactive?.type) 
                                            el.reply.actionType = menuRedirect.interactive.type
                                        else
                                            el.reply.actionType = el.reply.action
                                } else
                                    el.reply.actionType = el.reply.action
                                el.reply.topMenuId = menu._id || undefined
                                return el
                            })
                            await Promise.all(p)
                        }

                        return menu
                    })
                    await Promise.all(promise)
                    console.log('menus',resp.menus)
                    this.menus = resp.menus
                }
            }
            this.selectMenuOptions = []
            this.setSelectMenuOptions()
        },
        async setSelectMenuOptions() {
            let mainMenuIndex
            
            if(this.menuId)
                mainMenuIndex = this.menus.findIndex(el => el._id === this.menuId)
            else
                mainMenuIndex = this.menus.findIndex(el => !el.topMenuId)

            const mainMenu = this.menus[mainMenuIndex]
            if(mainMenu.interactive?.type) {
                if(mainMenu.interactive.type == 'list') {
                    if(mainMenu.interactive?.action?.sections?.length)
                        for await(const section of mainMenu.interactive.action.sections) {
                            for (const row of section.rows) {
                                row.preTitle = `Sessão ${section.title}`
                                this.selectMenuOptions.push({
                                    value: row,
                                    text: `Sessão ${section.title} - ${row.title}`,
                                    submenus: await this.getOptionsSubmenus(row)
                                })
                            }
                        }
                } else if(mainMenu.interactive.type == 'button')
                    if(mainMenu.interactive?.action?.buttons?.length)
                        for await(const button of mainMenu.interactive.action.buttons) {
                            button.reply.preTitle = "Botão"
                            button.reply._id = button._id
                            this.selectMenuOptions.push({
                                value: button.reply,
                                text: `Botão - ${button.reply.title}`,
                                submenus: await this.getOptionsSubmenus(button.reply)
                            })  
                        }
            } else
                if(mainMenu.menus?.length)
                    for await(const option of mainMenu.menus)
                        this.selectMenuOptions.push({
                            value: option,
                            text: `${option.option} - ${option.textOption}`,
                            submenus: await this.getOptionsSubmenus(option)
                        })  
        },
        async getOptionsSubmenus(option) {
            let submenus = []
            if(option.actionType == 'submenu') {
                const submenusResp = await api.getMenuRefs(option._id)
                if(submenusResp.statusCode == 200 && submenusResp.menu.menus?.length) {
                    for(const op of submenusResp.menu.menus) {
                        let find
                        this.menus.forEach(menu => {
                            if(menu.menus?.length && !find)
                                find = menu.menus.find(el => el._id == op._id)
                        })
                        submenus.push({
                            value: find,
                            text: `${find.option} - ${find.textOption}`,
                            submenus: await this.getOptionsSubmenus(find)
                        })
                    }
                }
            } else if (option.actionType == 'list') {
                const submenusResp = await api.getMenuRefs(option._id)
                if(submenusResp.statusCode == 200 && submenusResp.menu.interactive.action.sections?.length) {
                    for(let op of submenusResp.menu.interactive.action.sections) {
                        let find
                        this.menus.forEach(menu => {
                            // if(menu.menus?.length && !find)
                            //     find = menu.menus.find(el => el._id == op._id)
                            if(menu.interactive.action.sections?.length && !find)
                                find = menu.interactive.action.sections.find(el => el._id == op._id)
                            // if(menu.interactive.action.buttons?.length && !find)
                            //     find = menu.interactive.action.buttons.find(el => el._id == op._id)
                        })
                        if(find && find.rows?.length) 
                            for (const row of find.rows) {
                                row.preTitle = `Sessão ${find.title}`
                                submenus.push({
                                    value: row,
                                    text: `Sessão ${find.title} - ${row.title}`,
                                    submenus: await this.getOptionsSubmenus(row)
                                })
                            }
                    }
                }
            } else if (option.actionType == 'button') {
                // console.log(option)
                const submenusResp = await api.getMenuRefs(option._id)
                if(submenusResp.statusCode == 200 && submenusResp.menu.interactive.action.buttons?.length) {
                    for(const op of submenusResp.menu.interactive.action.buttons) {
                        let find = null
                        this.menus.forEach(menu => {
                            if(menu.menus?.length && !find)
                                find = menu.menus.find(el => el._id == op._id)
                            if(menu.interactive.action.sections?.length && !find)
                                find = menu.interactive.action.sections.find(el => el._id == op._id)
                            if(menu.interactive.action.buttons?.length && !find)
                                find = menu.interactive.action.buttons.find(el => el._id == op._id)
                        })
                        if(find) {
                            find.reply.preTitle = "Botão"
                            find.reply._id = find._id
                            submenus.push({
                                value: find.reply,
                                text: `Botão - ${find.reply.title}`,
                                submenus: await this.getOptionsSubmenus(find.reply)
                            })
                        }
                    }
                }
            }
            return submenus
        },
        selectMenu(item) {
            this.selectedMenu = item
        },
        verifyKanban(e) {
            if (e && !this.channelConfig.enableCustomerPortfolio) {
                this.$emit("msg", {
                    text: "A Carteira de clientes precisa estar habilitada para ativar o módulo de Kanban.",
                    type: "error"
                })

                this.channelConfig.modules.kanban = false
            }
        }
    }
}
</script>